export const filteredDate = (date, storeHours, store) => {
  if (!date) return true;
  // console.log('빼온 시간:'+ storeHours);
  // console.log('선택된 스토어:' + store);
  // console.log('설정 날짜:'+ date)
  // * return day !== 0; // 0 is Sunday. 0이면 false반환하는거

  const checkDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  const selectedStoreHours = storeHours.find(
    (s) => s.storeId.toString() === store
  );

  // todo: 선택한 스토어에 대한 시간 가져오고, 날짜 가져와서 블락하면 될거 같은데
  // todo: 현재 시간은 잘 가져와지는데, 흠 스토어를 비교해야될거 같아
  // todo: 이미 스페셜 날짜는 잘 가져오고 있음 이거 데이터 형식으로 바꿔서 리턴하면 될거같은데? . ㅇ
  // date":"2025-01-11T00:00:00.000Z"
  const blockedStoreDates = [];
  // 새로운 배열 생성이나 반환값 필요하면 map, 단순 반복 작업 필요하면 forEach
  selectedStoreHours?.specialDates.forEach((item) => {
    // console.log('날짜 닫혔는지:' + item.isClosed);
    if (item.isClosed) {
      // console.log("날짜확인" + JSON.stringify(item));
      // console.log("데이터 값 확인:" + item.date);
      const [year, month, day] = item.date.split("T")[0].split("-");
      // const date = new Date(item.date);
      // todo: 여기서 UTC 기준으로 받게 해야할듯
      // console.log("스페셜 날짜 배열에 데이터 값 확인:" + date);
      const convertDate = new Date(year, month - 1, day);
      // console.log("스페셜 날짜 컨벌트 한 값 확인:" + convertDate);
      // console.log("스페셜 날짜랑 비교할 값 확인:" + checkDate);
      // todo: 여기선 비교 안하고 스페셜 날짜 해당하는거 그냥 넣기만 하면 될거같음.
      // todo: 여기서 블락 형식을 시간은 없애는 형식으로 바꾼거

      // todo: 이거 날짜 추가할때, 만약 special close일때만 넣어야 할거 같음. 이건 지금 했고, regularHours 가 close일때는?
      if (convertDate && !blockedStoreDates.includes(convertDate)) {
        blockedStoreDates.push(convertDate);
      }
      // console.log('배열값:'+ blockedStoreDates);
    }
  });
  // todo: 지금 문제가 프론트 막히는 값이 하루 전으로 밀린다,
  // todo: 이거 배열에 넣은다음에 some으로 동일하게 해야될거 같음

  // console.log("블락 날짜:" + blockedStoreDates);
  const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
  const dateString = date.toISOString().split("T")[0];
  const hasSpecialDate = selectedStoreHours?.specialDates.find((item) => {
    // console.log("item 값:" + JSON.stringify(item));
    // console.log("item.date값:" + item.date);

    return item.date.split("T")[0] === dateString;
  });

  const isRegularClosed =
    hasSpecialDate
      ? false
      : selectedStoreHours?.regularHours[dayOfWeek]?.isClosed;

  // console.log("데이트스트링:" + dateString);
  // console.log("특정 날짜:" + hasSpecialDate);
  // console.log("평범 날짜 잠겼는지:" + isRegularClosed);

  const isSpecialClosed = blockedStoreDates.some(
    (blockedDate) => blockedDate.getTime() === checkDate.getTime()
  );

  // todo: 여기서 isRegularClosed 확인해봐야함. 이거 확인한다음에,

  return !(isRegularClosed || isSpecialClosed);
};

export const isSunday = (date) => {
  // console.log('날짜: ' + date);
  if (!date) return false;

  const day = date.getDay();
  return day === 0; // 0 is Sunday, 6 is Saturday
};
