// TODO: type 보내는 곳만 정리

export const toggleQRScanner = () => ({
    type: "TOGGLE_QR_SCANNER",
  });
  
  // * 이거 qr 성공했을 때 정보 확인하는거
  
  export const setIdentifyInfo = (info) => ({
    type: "SET_IDENTIFY_INFO",
    payload: info,
  });
  
  export const toggleContactButton = () => ({
    type: "TOGGLE_CONTACT_BUTTON",
  });
  
  export const toggleSubscribeAlert = () => ({
    type: "TOGGLE_SUBSCRIBE_ALERT",
  });
  
  export const toggleCancelSubscriptionAlert = () => ({
    type: "TOGGLE_CANCEL_SUBSCRIPTION_ALERT",
  });
  
  export const updateUser = (field, value) => ({
    type: "UPDATE_USER",
    payload: { field, value },
  });