export const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, "");
    // ! 문자열에서 숫자가 아닌 모든 문자 제거하는거임

    if (phoneNumber.length <= 3) {
      return phoneNumber;
      // ! 3개 이하면 그대로
    } else if (phoneNumber.length <= 6) {
      // ! 6개 이하면 - 자동 설립해주고
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    } else {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const phoneNumberRegex = /^\d{3}-\d{3}-\d{4}$/;