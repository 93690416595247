import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { login } from "../actions/action/authActions";
import { getAccessToken } from "../auth/authManager";


export const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-black"></div>
  </div>
);

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  // TODO: 이거 나중에 authenticated 뺼거임.
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const login_result = await dispatch(login({ email, password }));
      // console.log('로그인:'+ JSON.stringify(login_result));
      // console.log('로그인:'+ JSON.stringify(login_result.type));
      // console.log(login_result.msg);
      if (login_result.type === "LOGIN_FAIL") {
        // setStatusMessage("Invalid account or password. Please try again.");
        setStatusMessage(login_result.msg);
        // console.log(login_result.msg);
      }
      if (login_result.type === "NON_MEMBER") {
        // todo: 알림창으로 구독 해야된다고 뜨기
        // todo: 여기다가 구독 navigate 만들면 될듯
        // console.log(login_result.msg);
        setStatusMessage(login_result.msg);
        // setShowSubscriptionAlert(true);
        // navigate('/subscription')
      }
    } catch (error) {
      setStatusMessage("An unexpected error occurred. Please try again.");
    }
  };

  // const handleSubscriptionRedirect = () => {
  //   navigate("/subscription");
  // };

  if (loading) {
    
    return LoadingSpinner();
  }

  return (
    <div id="loginForm" className="login-container">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Member Email</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button className="login-button" type="submit">
          Access Your Account
        </button>
      </form>
      <div className="status-message">{statusMessage}</div>

      <div className="mt-4 text-center flex justify-between text-sm sm:text-base">
        <Link
          to="/login/recovery"
          className="text-blue-500 hover:text-blue-700 font-semibold underline sm:text-base text-sm"
        >
          Forgot ID or Password?
        </Link>
        <Link
          to="/register"
          className="text-blue-500 hover:text-blue-700 font-semibold underline sm:text-base text-sm"
        >
          Register Now
        </Link>
      
      </div>
    </div>
  );
}

export default Login;



