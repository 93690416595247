import api from "../config/api";

export const reserveSchedule = (reservationData) => async (dispatch) => {
  try {
    // console.log('예약정보 보내온거:'+JSON.stringify(reservationData));
    const res = await api.post("/reservation/calendar", reservationData);
    // console.log('res'+res);
    // console.log('res'+JSON.stringify(res));
    // console.log('예약 요청 결과:'+res);

    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve({ status: 200 }); // Simulate successful API response
    //   }, 1000);
    // });
    return res;
  } catch (error) {
    // console.error('예약 요청 에러:'+JSON.stringify(error.response.data));
    // console.error('예약 요청 에러:'+error.response.status);
    // console.error('예약 요청 에러:'+JSON.stringify(error.response));
    return error.response;
  }
};

export const sendEmailService = (emailData) => async () => {
  try {
    let response = await api.post("/reservation/send", emailData);

    return response.status;
    // status 200 반환
  } catch (error) {
    console.error(error);
    return error;
  }
};
