import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { FaCalendarAlt } from "react-icons/fa";
import {
  handleDateChange,
  handleStoreChange,
} from "../hooks/reservationHooks/handleReservation";
import { fetchStoreHours } from "../actions/action/storeHourActions";
import { reserveSchedule } from "../utils/service/reserveService";
import { featuredStore as stores } from "./Events";
import ReservationTimes from "./UI/ReservationTimes";
import {
  initializeGA4,
  trackButtonClick,
  trackPageView,
  trackReservationAttempt,
} from "../hooks/reservationHooks/ga4.codes";
import {
  emailRegex,
  phoneNumberRegex,
  formatPhoneNumber,
} from "../hooks/formatRegax";
import {
  filteredDate,
  isSunday,
} from "../hooks/reservationHooks/datePickerFilter";
import { openTerms } from "./Subscription";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import NotificationAlert from "./NotificationAlert";

function Reservation() {
  // const { eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [store, setStore] = useState("");
  // ! 10월 3일, 지금 store 값으로 받고 있는거 store.id 임, 이름이 아니라 id
  const [selectedService, setSelectedService] = useState("");

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState(null);
  const [time, setTime] = useState("");
  const [phone, setPhone] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const [isShowNotification, setIsShowNotification] = useState(false);

  const isAdvertisement = true;

  const location = useLocation();

  const [request, setRequest] = useState("");
  const [reservationStatus, setReservationStatus] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const storeHours = useSelector((state) => state.store?.hours ?? []);

  const onClose = async () => {
    setIsShowNotification(false);
    setReservationStatus("");
  };

  const mountTime = Date.now();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // ? 시간을 00:00:00.000으로 설정하는 것. 처음 시간, 다음 분, 다음 초, 다음 밀리초.
  // ? 예를들어 얻는 값은 오늘이 2024년 10월 7일이면, today는 2024-10-07T00:00:00.000Z 값을 받음.
  // ! 오늘 이전의 값들을 설정 못하도록 하려고 만듬

  const services = ["Massage", "Beauty"];

  useEffect(() => {
    initializeGA4();
    trackPageView();
    setStore("2");
    return () => {
      if (process.env.NODE_ENV === "production") {
        ReactGA.event({
          category: "User Engagement",
          action: "Leave Reservation Page",
          label: "Time Spent",
          value: Math.floor((Date.now() - mountTime) / 1000),
        });
      }
    };
  }, []);

  useEffect(() => {
    // Pre-fill email and name if user is logged in
    if (user) {
      setEmail(user.email);
      setName(user.name);
      setPhone(user.phoneNumber);
    }
  }, [user]);

  useEffect(() => {
    
    dispatch(fetchStoreHours());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let reservationData;
    if (!emailRegex.test(email)) {
      setReservationStatus("Please enter a valid email address");
      return;
    }

    if (!store) {
      setReservationStatus("Please select a store");
      return;
    }
    // Date validation
    if (!date) {
      setReservationStatus("Please select a date");
      return;
    }

    // Time validation
    if (!time) {
      setReservationStatus("Please select a time");
      return;
    }

    if (store === "2" && !selectedService) {
      setReservationStatus("Please select a service");
      return;
    }

    // Name validation
    if (!name || name.trim().length === 0) {
      setReservationStatus("Please enter your name");
      return;
    }

    if (!selectedService) {
      setReservationStatus("Please select a service");
      return;
    }

    if (!agreedToTerms) {
      setReservationStatus("Please agree to the terms and conditions");
      return;
    }

    if (!phoneNumberRegex.test(phone)) {
      setReservationStatus("The PhoneNumber format must be '111-111-1111' ");
      return;
    }
    setIsSubmitting(true);

    try {
      const selectedStore = stores.find((s) => s.id.toString() === store);
      // const selectedStoreEmail = stores.find((store) => store.email.toString() === selectedStore);
      // const [hours, minutes] = time.split(":").map(Number);
      // Number함수 = 숫자로 변환 . parseInt 같은거네
      // Combine date and time into a string
      const dateString = date.toISOString().split("T")[0]; // Get YYYY-MM-DD
      const reservationDateTime = `${dateString}`; // Format: YYYY-MM-DDTHH:mm:00

      reservationData = {
        email: email,
        name: name,
        phone: phone,
        store: selectedStore.name,
        service: selectedService,
        store_email: selectedStore.email,
        reservation_date: reservationDateTime,
        reservation_time: time,
        comments: request,
        agreedToTerms: agreedToTerms,
        isAdvertisement,
      };
      // console.log("reservationData:" + reservationData);
      // action
      const response = await dispatch(reserveSchedule({ reservationData }));
      // const response = await axios.post("/api/booking", reservationData);
      if (response.status === 201 || response.status === 200) {
        trackReservationAttempt(reservationData, true);

        setReservationStatus("Reservation successful!");

        // Reset form
        if (!user) {
          setEmail("");
          setName("");
          setPhone("");
        }
        setStore("");
        setDate(null);
        setTime("");
        setRequest("");
        setAgreedToTerms(false);
        setSubmitStatus(true);
        // setIsShowNotification(true);
      } else if (response.status === 400) {
        trackReservationAttempt(reservationData, false, response.data.message);
        setReservationStatus(response.data.message);
        setSubmitStatus(false);
        // setIsShowNotification(true);
      }
    } catch (error) {
      // console.error("Error submitting reservation:", error);
      trackReservationAttempt(
        reservationData,
        false,
        error.message || "Unknown error occurred"
      );
      setSubmitStatus(false);
      // setIsShowNotification(true);
      setReservationStatus("Failed to make reservation. Please try again.");
    } finally {
      setIsShowNotification(true);
      setIsSubmitting(false); // Stop loading
    }
  };

  return (
    <>
      <Helmet>
        <title>CERAGEM Reservation Page only for West Hollywood</title>
        <meta
          name="description"
          content="Book now for the ultimate wellness experience at the CERAGEM Lounge in West Hollywood. Indulge in Cutting-edge Massage and K-Beauty Med Spa."
        />
        <meta
          property="og:title"
          content="CERAGEM Reservation only for West Hollywood"
        />
        <meta
          property="og:description"
          content="Indulge in Cutting-edge Massage and K-Beauty Med Spa at CERAGEM Lounge."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://ceragem-membership.com/reservation"
        />
      </Helmet>
      <div className="min-h-screen">
        <div className="w-full bg-white py-8 px-4 border-b">
          <div className="max-w-4xl mx-auto text-center space-y-3">
            {/* Decorative Top Element */}

            {/* Main Heading with Enhanced Typography */}
            <div className="flex justify-center gap-1 mb-4">
              <div className="w-1 h-1 rounded-full bg-red-500 animate-pulse"></div>
              <div className="w-1 h-1 rounded-full bg-red-400 animate-pulse delay-100"></div>
              <div className="w-1 h-1 rounded-full bg-red-300 animate-pulse delay-200"></div>
            </div>

            {/* Main Heading with Enhanced Typography - Full Width and Responsive */}
            <div className="relative w-full">
              <h1 className="text-xl sm:text-2xl md:text-[2rem] leading-tight font-extrabold text-gray-900 px-2 md:px-4">
                Book now for the
              </h1>
              <h2 className="text-xl sm:text-xl md:text-[1.7rem] leading-tight font-bold mb-4 px-2 md:px-4">
                <span className="text-red-500">
                  ultimate wellness experience
                </span>
              </h2>
            </div>

            {/* Subtitle with Accent Line */}
            <div className="relative">
              <p className="text-base sm:text-lg text-gray-700 font-medium px-2">
                at the{" "}
                <span className="text-red-500 font-semibold">
                  CERAGEM Lounge
                </span>{" "}
                in West Hollywood
              </p>
              <div className="h-px w-16 bg-gradient-to-r from-transparent via-red-400 to-transparent mx-auto mt-4"></div>
              <p className="text-sm text-gray-500 mt-3 font-light tracking-wide">
                Cutting-edge Massage and K-Beauty Med Spa <br></br> Day Pass 40%
                Off During the Holiday Season
              </p>

              {/* <p className="text-sm text-gray-500 mt-3 font-light tracking-wide">
              At CERAGEM West Hollywood, 8951 Santa Monica Blvd, West Hollywood,
              CA 90069
            </p> */}
            </div>
          </div>
        </div>

        <div className="sm:h-auto mx-auto bg-white rounded-lg shadow-md p-6">
          <h1 className="text-2xl font-bold text-black">Reserve your visit</h1>
          <p className="text-sm text-gray-500 font-light tracking-wide">
            CERAGEM West Hollywood, 8951 Santa Monica Blvd, West Hollywood, CA
            90069
          </p>
          <form onSubmit={handleSubmit} className="space-y-4 mt-11 mb-6">
            {!user && (
              <>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-red-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                  />
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-red-700"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-red-700"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    value={phone}
                    onChange={(e) =>
                      setPhone(formatPhoneNumber(e.target.value))
                    }
                    placeholder="(123) 456-7890"
                    required
                    className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                  />
                </div>
              </>
            )}
            <div>
              <label
                htmlFor="store"
                className="block text-sm font-medium text-red-700"
              >
                Select Store
              </label>
              <select
                id="store"
                value={store}
                //
                onChange={(e) => {
                  handleStoreChange({
                    e,
                    setStore,
                    setDate,
                    setSelectedService,
                    setTime,
                    date,
                  });
                }}
                required
                className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none appearance-none"
              >
                <option className="py-3" value="">
                  Choose a store
                </option>
                {stores.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div>
                <label className="block text-sm font-medium text-red-700 mb-2">
                  Select Service
                </label>
                <div className="flex space-x-4 mt-3">
                  {/* 여기가 weho이면 골라야할듯 */}
                  {store === "2" ? ( // West Hollywood
                    <>
                      {date && isSunday(date) ? (
                        // Weekend - Only show Massage

                        <label className="flex items-center space-x-2">
                          <input
                            type="radio"
                            value="Massage"
                            checked={true}
                            onChange={() => setSelectedService("Massage")}
                            className="form-radio h-5 w-5 text-red-600"
                            required
                          />
                          <span className="text-gray-700">Massage</span>
                        </label>
                      ) : (
                        // Weekday - Show both options
                        services.map((service) => (
                          <label
                            key={service}
                            className="flex items-center space-x-2"
                          >
                            <input
                              type="radio"
                              value={service}
                              checked={selectedService === service}
                              onChange={(e) =>
                                setSelectedService(e.target.value)
                              }
                              className="form-radio h-5 w-5 text-red-600"
                              required
                            />
                            <span className="text-gray-700">{service}</span>
                          </label>
                        ))
                      )}
                      {date && isSunday(date) && (
                        <label className="flex items-center space-x-2">
                          <input
                            type="radio"
                            value="Massage"
                            checked={false}
                            onChange={() => setSelectedService("Massage")}
                            className="form-radio h-5 w-5 text-red-600"
                            required
                          />
                          <span className="text-gray-700">
                            Beauty service is not available on weekends
                          </span>
                        </label>
                      )}
                    </>
                  ) : (
                    // Other stores - Only show Massage
                    <label className="flex items-center space-x-2">
                      <input
                        type="radio"
                        value="Massage"
                        checked={true}
                        onChange={() => setSelectedService("Massage")}
                        className="form-radio h-5 w-5 text-red-600"
                        required
                      />
                      <span className="text-gray-700">Massage</span>
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium text-red-700"
              >
                Select Date
              </label>
              <div className="relative">
                <DatePicker
                  selected={date}
                  onChange={(e) => {
                    handleDateChange({
                      selectedDate: e,
                      setDate,
                      setTime,
                      setSelectedService,
                      selectedService,
                      store,
                    });
                  }}
                  minDate={today}
                  filterDate={(date) => filteredDate(date, storeHours, store)}
                  dateFormat="MMMM d, yyyy"
                  className="mt-1 block w-full py-3 pl-3 pr-10 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
                  wrapperClassName="w-full"
                  required
                />
                <FaCalendarAlt className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            <div>
              <label
                htmlFor="time"
                className="block text-sm font-medium text-red-700"
              >
                Select Time
              </label>
              <ReservationTimes
                store={store}
                date={date}
                selectedService={selectedService}
                onTimeSelect={(value) => setTime(value)}
              />
            </div>
            <div>
              <label
                htmlFor="request"
                className="block text-sm font-medium text-red-700"
              >
                Special Requests or Comments
              </label>
              <textarea
                id="request"
                value={request}
                onChange={(e) => setRequest(e.target.value)}
                placeholder="Any special requests or comments for your reservation?"
                rows="3"
                className="mt-1 p-3 block w-full rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none"
              />
            </div>
            <div className="flex items-start space-x-2">
              <div className="flex items-center h-5">
                <input
                  id="terms"
                  type="checkbox"
                  checked={agreedToTerms}
                  onChange={(e) => setAgreedToTerms(e.target.checked)}
                  required
                  className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                />
              </div>
              <div className="ml-2 text-sm">
                <label htmlFor="terms" className="font-medium text-gray-700">
                  I have read and agree to the{" "}
                  <button
                    type="button"
                    onClick={openTerms}
                    className="text-red-600 hover:text-red-500 underline"
                  >
                    terms and conditions
                  </button>
                </label>

                {/* <p className="text-gray-500">
                By checking this box, you agree to our Terms of Service and
                Privacy Policy
              </p> */}
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              onClick={() => trackButtonClick("Submit Reservation")}
              className="w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-300 ease-in-out disabled:opacity-75 disabled:cursor-not-allowed relative h-[42px]"
            >
              <div className="absolute inset-0 flex items-center justify-center">
                {isSubmitting ? (
                  <div className="flex gap-1">
                    <div className="w-1.5 h-1.5 rounded-full bg-white animate-bounce"></div>
                    <div className="w-1.5 h-1.5 rounded-full bg-white animate-bounce [animation-delay:0.2s]"></div>
                    <div className="w-1.5 h-1.5 rounded-full bg-white animate-bounce [animation-delay:0.4s]"></div>
                  </div>
                ) : (
                  "Confirm Reservation"
                )}
              </div>
            </button>
          </form>
          {reservationStatus && (
            <p
              className={`mt-4 ${
                submitStatus ? "text-green-600" : "text-red-600"
              } font-semibold text-center`}
            >
              {reservationStatus}
            </p>
          )}
          <button
            onClick={() => navigate("/home")}
            className="mt-4 text-black hover:text-slate-600 underline"
          >
            Go to MainPage
          </button>
        </div>
        <NotificationAlert
          show={isShowNotification}
          message={reservationStatus}
          type={submitStatus === true ? "success" : "error"}
          onClose={onClose}
        />
        <ServiceSlideshow />
      </div>
    </>
  );
}

export default Reservation;

const ServiceSlideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const slides = [
    {
      imageDesktop: "/core.jpeg",
      imageUrl: "/core.png",
      alt: "Core Session Services",
    },
    {
      imageDesktop: "/beauty.jpeg",
      imageUrl: "/beauty.png",
      alt: "Beauty Session Services",
    },
    {
      imageDesktop: "/wellness.jpeg",
      imageUrl: "/wellness.png",
      alt: "Wellness Membership Services",
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      nextSlide();
    }
    if (touchStart - touchEnd < -50) {
      prevSlide();
    }
  };

  const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
  const prevSlide = () =>
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);

  return (
    <div className="flex flex-col items-center w-full sm:mt-8">
      <div className="relative w-full min-h-screen bg-white pb-10 rounded-lg shadow-md">
        <div className="w-full bg-white py-8 px-4">
          <div className="max-w-4xl mx-auto text-center space-y-3">
            {/* Decorative Top Element */}

            {/* Main Heading with Enhanced Typography */}
            <div className="h-px w-16 bg-gradient-to-r from-transparent via-red-400 to-transparent mx-auto mb-6"></div>

            {/* Main Heading with Enhanced Typography - Full Width and Responsive */}
            <div className="relative w-full">
              <h1 className="text-xl sm:text-2xl md:text-[2rem] leading-tight font-extrabold text-gray-900 px-2 md:px-4">
                Receive 40% Off
              </h1>
              <h2 className="text-xl sm:text-xl md:text-[1.7rem] leading-tight font-bold sm:mb-2 px-2 md:px-4">
                <span className="text-black-500">on Day Pass Today</span>
              </h2>
            </div>
          </div>
        </div>
        <div
          className="relative h-[calc(100vh-4rem)] p-0 md:h-[600px]"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-all duration-700 ease-in-out ${
                currentSlide === index
                  ? "opacity-100 transform scale-100"
                  : "opacity-0 transform scale-95"
              }`}
            >
              <picture>
                <source
                  media="(min-width: 768px)"
                  srcSet={slide.imageDesktop}
                />
                <img
                  src={isMobile ? slide.imageUrl : slide.imageDesktop}
                  alt={slide.alt}
                  className="w-full h-full object-contain"
                />
              </picture>
            </div>
          ))}

          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 -translate-y-1/2 p-2 md:p-3 bg-black/20 hover:bg-black/40 rounded-full transition-all duration-300 transform hover:scale-105 active:scale-95 touch-manipulation"
          >
            <ChevronLeft className="w-5 h-5 md:w-6 md:h-6 text-white" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 p-2 md:p-3 bg-black/20 hover:bg-black/40 rounded-full transition-all duration-300 transform hover:scale-105 active:scale-95 touch-manipulation"
          >
            <ChevronRight className="w-5 h-5 md:w-6 md:h-6 text-white" />
          </button>

          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2 z-10">
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-2 h-2 rounded-full transition-all duration-300 ${
                  currentSlide === index
                    ? "bg-red-600 w-4"
                    : "bg-gray-400 hover:bg-red-400"
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
