import api from "../../utils/config/api.js";
import { useSelector } from "react-redux";

import { updateUser } from "../type/types.js";
// import { TypeIcon } from "lucide-react";

export const login = (credentials) => async (dispatch) => {
  dispatch({ type: "LOGIN_REQUEST" });
  try {
    // console.log("Attempting login with:", credentials);
    const res = await api.post("/auth/login", credentials);
    // console.log("Server response:", res);

    // ? status 400이면 바로 error로 넘어오는거 가튼데
    // ? 프론트가 400 받았을때

    if (res.status === 200) {
      // setAccessToken(res.data.token);
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: res.data,
      });
      return { type: "LOGIN_SUCCESS" };
    } else {
      // console.error("Unexpected response structure:", res.data);
      return { type: "LOGIN_FAIL" };
      // if(res.data.type === "NON_MEMBER"){
      //   throw new Error(res.data);
      // }

      // throw new Error(res.data.msg);
    }
  } catch (err) {
    // console.error("Login error:", err);
    if (err.response?.data?.type === "NON_MEMBER") {
      dispatch({
        type: "NON_MEMBER",
        payload: err.response.data,
      });
      return {
        type: err.response.data.type,
        msg: err.response.data.msg,
      };
    }

    dispatch({
      type: "LOGIN_FAIL",
      payload: err.response?.data?.msg || "An error occurred during login",
    });
    return {
      type: "LOGIN_FAIL",
      msg: err.response?.data?.msg || "Login failed",
    };
  }
};

export const checkAuth = () => async (dispatch) => {
  // const token = getAccessToken();

  // if (!token) {
  //   dispatch({ type: "AUTH_FAIL" });
  //   return;
  // }
  // console.log("Starting auth check...");
  dispatch({ type: "AUTH_CHECK_START" });

  try {
    // console.log("Sending verify request...");
    const res = await api.get("/auth/verify");
    // console.log("Verify response:", res.data);
    // console.log("Verify response data.user:", res.data.user);

    if (res.data) {
      dispatch({
        type: "AUTH_SUCCESS",
        payload: { user: res.data.user },
      });
    } else {
      throw new Error("Invalid response from server");
    }
  } catch (err) {
    // console.error("Auth check error:", err);
    dispatch({ type: "AUTH_FAIL" });
    return;
    // clearAccessToken();
  }
};

export const logout = () => async (dispatch) => {
  try {
    // Call the logout endpoint
    await api.post("/auth/logout");
    // clearAccessToken();
    // Dispatch logout action to update Redux state
    dispatch({ type: "LOGOUT" });

    // Clear access token from local storage or memory

    // or
    // clearAccessToken(); // If you're using a custom function to manage the token in memory

    // Optionally, redirect to login page
    // history.push('/login');
  } catch (error) {
    // console.error("Logout error:", error);
    // Even if the server request fails, we still want to clear local state
    dispatch({ type: "LOGOUT" });
    // clearAccessToken();
  }
};

export const register = (userData) => async (dispatch) => {
  dispatch({ type: "REGISTER_REQUEST" });
  try {
    // console.log(JSON.stringify(userData));
    const res = await api.post("/auth/register", userData);
    // console.log('등록 왜 오류:', res);
    dispatch({
      type: "REGISTER_SUCCESS",
      payload: res.data,
    });
    return { type: "REGISTER_SUCCESS" };
  } catch (err) {
    // console.error("에러 왜오류:"+err);
    dispatch({
      type: "REGISTER_FAIL",
      payload: err.response?.data?.msg || "Registration failed",
    });
    return { type: "REGISTER_FAIL", status: err.response.status };
  }
};

export const deleteAccount = (password) => async (dispatch) => {
  // console.log('액션 비밀번호: password');

  try {
    // console.log('try진입');
    const response = await api.post("/auth/schedule-delete-account", {
      password,
    });

    // console.log("삭제 응답 데이터:" + response.data);

    if (response.status === 200) {
      // todo: 수정해야함.
      dispatch(
        updateUser("scheduledDeletionDate", response.data.scheduledDeletionDate)
      );
      return { message: response.data.message, isSuccess: true };
    } else {
      return { message: response.data.message, isSuccess: false };
    }
  } catch (error) {
    // console.log("계정 삭제 에러:" + error);
    return {
      message:
        error.response?.data?.message || "Failed to schedule account deletion",
      isSuccess: false,
    };
  }
};

export const cancelDeleteAccount = (password) => async (dispatch) => {
  try {
    const response = await api.post("/auth/cancel-delete-account", {
      password,
    });

    if (response.status === 200) {
      dispatch(updateUser("scheduledDeletionDate", null));

      return {
        message: "Account deletion cancellation success.",
        isSuccess: true,
      };
    } else {
      return {
        message: response.data.message || "Unexpected Error Occurred",
        isSuccess: false,
      };
    }
  } catch (error) {
    return {
      message: error.response.data.message || "Unexpected Error Occurred",
      isSuccess: false,
    };
  }
};
