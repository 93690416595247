import { isSunday } from "./datePickerFilter";
import { emailRegex, phoneNumberRegex } from "../formatRegax";
import { reserveSchedule } from "../../utils/service/reserveService";




export const handleStoreChange = ({e, setStore, setDate, setSelectedService, setTime, date}) => {

  const storeId = e.target.value;
    // console.log('스토어 아이디:'+ storeId);
  setStore(storeId);

  setDate(null);
  setTime("");

  // Reset service when store changes
  if (storeId === "2") {
    // West Hollywood
    if (date && isSunday(date)) {
      setSelectedService("Massage"); // Force Massage on weekends
    } else {
      setSelectedService("Massage");
    }
  } else {
    setSelectedService("Massage");
  }
  setTime("");
};

  export const handleDateChange = ({
    selectedDate,
    setDate,
    setTime,
    setSelectedService,
    selectedService,
    store
  }) => {
    
    setDate(selectedDate);
    setTime("");

    // If West Hollywood is selected, check if we need to update service
    if (store === "2") {
      // West Hollywood
      if (isSunday(selectedDate)) {
        setSelectedService("Massage"); // Force Massage on weekends
      } else if (selectedService === "Massage" && !isSunday(selectedDate)) {
        setSelectedService("Massage"); // Reset selection on weekdays
      }
    }
  };

