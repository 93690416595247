import ReactGA from "react-ga4";

export const getReferrerInfo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      source: urlParams.get("utm_source") || "direct",
      medium: urlParams.get("utm_medium") || "",
      campaign: urlParams.get("utm_campaign") || "",
      raw_referrer: document.referrer || "direct",
      full_url: window.location.href,
      landing_page: window.location.pathname + window.location.search,
    };
  };

  export const initializeGA4 = () => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize("G-4L9JX65TFT", {
        gaOptions: {
          cookieFlags: "SameSite=None;Secure",
          storage: "none",
          storeGac: false,
        },
        gtagOptions: {
          send_page_view: false,
        },
        debug: true,
      });
      const referrerInfo = getReferrerInfo();
      ReactGA.event({
        category: "User Acquisition",
        action: "Page Load",
        label: referrerInfo.source,
        referrer_info: referrerInfo,
        timestamp: new Date().toISOString(),
      });

      console.log("Referrer information:", referrerInfo); // 디
    }
  };

  export const trackButtonClick = (buttonName, additionalData = {}) => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "Button Interaction",
        action: "Click",
        label: buttonName,
        ...additionalData,
      });
      console.log(`Button clicked: ${buttonName}`, additionalData); // 디버깅용
    }
  };

  export const trackPageView = () => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: document.title,
        location: window.location.href,
        referrer: document.referrer || "direct",
      });
    }
  };

  export const trackReservationAttempt = (
    reservationData,
    success,
    errorMessage = null
  ) => {
    if (process.env.NODE_ENV === "production") {
      const referrerInfo = getReferrerInfo();

      // 페이지 로드 시간 계산을 위한 현대적인 방법
      const getPageLoadTime = () => {
        if (window.performance && window.performance.getEntriesByType) {
          const perfEntries = window.performance.getEntriesByType("navigation");
          if (perfEntries.length > 0) {
            return Math.round(perfEntries[0].loadEventEnd);
          }
        }
        return undefined;
      };

      ReactGA.event({
        category: "Reservation",
        action: success ? "Submit Success" : "Submit Failure",
        label: reservationData.store,
        value: success ? 1 : 0,
        nonInteraction: false,
        service_type: reservationData.service,
        reservation_date: reservationData.reservation_date,
        reservation_time: reservationData.reservation_time,
        referrer: document.referrer || "direct",
        error_message: errorMessage,
        user_type: reservationData.email ? "new" : "returning",
        device_type: /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
          navigator.userAgent
        )
          ? "mobile"
          : "desktop",
        page_load_time: getPageLoadTime(),
        timestamp: new Date().toISOString(), // 타임스탬프 추가
        viewport_size: `${window.innerWidth}x${window.innerHeight}`, // 뷰포트 크기 추가
        traffic_source: referrerInfo.source,
        traffic_medium: referrerInfo.medium,
        traffic_campaign: referrerInfo.campaign,
        raw_referrer: referrerInfo.raw_referrer,
        landing_page: referrerInfo.landing_page,
      });

      // 디버깅용 콘솔 로그
      console.log("Reservation attempt tracked:", {
        success,
        store: reservationData.store,
        service: reservationData.service,
        error: errorMessage,
      });
    }
  };
