import {
  FETCH_STORE_HOURS_REQUEST,
  FETCH_STORE_HOURS_SUCCESS,
  FETCH_STORE_HOURS_FAILURE,
} from "../actions/action/storeHourActions";

const initialState = {
  hours: [],
  // loading: false,
  // error: null
};

const storeHourReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORE_HOURS_SUCCESS:
      return {
        ...state,

        hours: action.payload,
      };

    default:
      return state;
  }
};

export default storeHourReducer;
