import React, { useEffect, useState } from "react";
import { Clock, Save, Edit2, X } from "lucide-react";
import { featuredStore } from "./Events";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchStoreHours,
  updateRegularHours,
  updateSpecialDate,
  deleteSpecialDate,
} from "../actions/action/storeHourActions";

const StoreHoursDashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user);
  const storeHours = useSelector((state) => state.store?.hours ?? []);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedStoreName, setSelectedStoreName] = useState("");
  const [editingDay, setEditingDay] = useState(null);
  const [editingSpecialDate, setEditingSpecialDate] = useState(null);
  const [editingTimes, setEditingTimes] = useState({
    open: "00:00",
    close: "00:00",
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedLocalDate, setSelectedLocalDate] = useState(null);

  const [view, setView] = useState("regular");
  const [showSuccess, setShowSuccess] = useState(false);
  const [isRegularDateClosed, SetIsRegularDateClosed] = useState(false);
  const [isSpecialDateClosed, setIsSpecialDateClosed] = useState(false);
  const [specialTimes, setSpecialTimes] = useState({
    open: "00:00",
    close: "00:00",
  });
  const [error, setError] = useState("");

  const currentStore = storeHours?.find(
    (store) => store.storeId === selectedStore
  );

  //   const [specialTimeOpen, setSpecialTimeOpen] = useState("09:00");
  //   const [specialTimeClose, setSpecialTimeClose] = useState("17:00");
  //   Todo: 로직 1. 페이지 들어오면 스토어 전체 시간 업데이트 2. 업데이트에 따라 dispatch
  //    Todo: 현재 구현중인 로직 1. 정규 운영 시간 설정 2. 특정 운영 날짜 및 시간 설정 3. regualr 날짜에도 close 옵션 넣어야 할듯.

  useEffect(() => {
    const loadStoreHours = async () => {
      try {
        await dispatch(fetchStoreHours());
      } catch (error) {
        console.error("Failed to fetch store hours:", error);
      }
    };
    if (user?.role === "admin") {
      loadStoreHours();
    }
  }, [dispatch]);

  const handleRegularHoursSave = async () => {
    try {
      console.log("시간 바꾸기 동작");
      await dispatch(
        updateRegularHours(selectedStore, editingDay, {
          open: editingTimes.open,
          close: editingTimes.close,
          isClosed: isRegularDateClosed,
        })
      );

      setShowSuccess(true);
      //   todo: 이거 notification 보여주는거로 바꿀거야
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Failed to save changes:", error);
      setError(error.message);
      //todo:   이거 notification 뜨게 하자
    } finally {
      setEditingDay(null);
      setEditingTimes({ open: "00:00", close: "00:00" });
      SetIsRegularDateClosed(false);
    }
  };

  const handleStoreSelect = (store) => {
    setSelectedStore(store.id);
    setSelectedStoreName(store.name);
    setEditingDay(null);
    setEditingSpecialDate(null);
  };

  const handleAddSpecialDate = async () => {
    if (!selectedDate || !selectedStore) return;

    try {
      const dateData = {
        date: selectedDate,
        open: specialTimes.open,
        close: specialTimes.close,
        isClosed: isSpecialDateClosed,
      };

      await dispatch(updateSpecialDate(selectedStore, dateData));

      setSelectedDate(null);
      setSpecialTimes({ open: "00:00", close: "00:00" });

      setIsSpecialDateClosed(false);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Failed to add special date:", error);
      setError(error.message);
      //todo:   이거 notification 뜨게 하자
    }
  };

  const handleRemoveSpecialDate = async (date) => {
    try {
      await dispatch(deleteSpecialDate(selectedStore, date));

      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Failed to remove special date:", error);
    }
  };

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleTimeChange = (timeType, value) => {
    setEditingTimes((prev) => ({
      ...prev,
      [timeType]: value,
    }));
  };

  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      timeOptions.push(`${formattedHour}:${formattedMinute}`);
    }
  }

  return user?.role === "admin" ? (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-5xl mx-auto px-4">
        {/* Header */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Clock className="text-blue-600 h-8 w-8" />
              <h1 className="text-3xl font-bold text-gray-900">
                Store Hours Management
              </h1>
            </div>
          </div>

          {/* Store Selection */}
          <div className="mt-6">
            <h2 className="text-sm font-medium text-gray-600 mb-3">
              Select Store
            </h2>
            <div className="flex flex-wrap gap-3">
              {featuredStore.map((store) => (
                <button
                  key={store.id}
                  onClick={() => handleStoreSelect(store)}
                  className={`px-5 py-2.5 rounded-lg transition-all duration-200 font-medium ${
                    selectedStore === store.id
                      ? "bg-red-600 text-white shadow-lg shadow-red-200"
                      : "bg-white border border-gray-200 hover:border-red-400 hover:bg-red-50"
                  }`}
                >
                  {store.name}
                </button>
              ))}
            </div>
          </div>
        </div>

        {selectedStore && (
          <div className="space-y-6">
            {/* View Toggle */}
            <div className="bg-white rounded-xl shadow-sm p-4">
              <div className="flex gap-3">
                <button
                  onClick={() => setView("regular")}
                  className={`flex-1 py-3 rounded-lg transition-all duration-200 font-medium ${
                    view === "regular"
                      ? "bg-red-600 text-white shadow-lg shadow-red-200"
                      : "bg-gray-50 hover:bg-gray-100"
                  }`}
                >
                  Regular Hours
                </button>
                <button
                  onClick={() => setView("special")}
                  className={`flex-1 py-3 rounded-lg transition-all duration-200 font-medium ${
                    view === "special"
                      ? "bg-blue-600 text-white shadow-lg shadow-blue-200"
                      : "bg-gray-50 hover:bg-gray-100"
                  }`}
                >
                  Special Dates
                </button>
              </div>
            </div>

            {/* Regular Hours Section */}
            {view === "regular" && (
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-xl font-bold text-gray-900 mb-6">
                  {selectedStoreName} Regular Schedule
                </h2>
                <div className="space-y-3">
                  {weekdays.map((day) => (
                    <div
                      key={day}
                      className="flex flex-col sm:flex-row sm:items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors space-y-4 sm:space-y-0"
                    >
                      <span className="font-medium text-gray-700 w-32">
                        {day}
                      </span>

                      {editingDay === day ? (
                        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
                          {!isRegularDateClosed && (
                            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 sm:gap-4 w-full sm:w-auto">
                              <select
                                className="w-full sm:w-auto p-2 border rounded-lg bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                value={editingTimes.open}
                                onChange={(e) =>
                                  handleTimeChange("open", e.target.value)
                                }
                                disabled={isRegularDateClosed}
                              >
                                {timeOptions.map((time) => (
                                  <option key={`start-${time}`} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              <span className="text-gray-500">to</span>
                              <select
                                className="w-full sm:w-auto p-2 border rounded-lg bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                value={editingTimes.close}
                                onChange={(e) =>
                                  handleTimeChange("close", e.target.value)
                                }
                                disabled={isRegularDateClosed}
                              >
                                {timeOptions.map((time) => (
                                  <option key={`end-${time}`} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}

                          <div className="flex items-center gap-4 justify-between w-full sm:w-auto">
                            <div className="flex items-center gap-2">
                              <input
                                type="checkbox"
                                id={`isClosed-${day}`}
                                checked={isRegularDateClosed}
                                onChange={(e) =>
                                  SetIsRegularDateClosed(e.target.checked)
                                }
                                className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                              />
                              <label
                                htmlFor={`isClosed-${day}`}
                                className="text-sm font-medium text-gray-600"
                              >
                                Closed
                              </label>
                            </div>

                            <div className="flex gap-2">
                              <button
                                onClick={handleRegularHoursSave}
                                className="p-2 text-green-600 hover:text-green-700 bg-green-50 rounded-lg"
                              >
                                <Save className="h-5 w-5" />
                              </button>
                              <button
                                onClick={() => {
                                  setEditingDay(null);
                                  setEditingTimes({
                                    open: "00:00",
                                    close: "00:00",
                                  });
                                  SetIsRegularDateClosed(false);
                                }}
                                className="p-2 text-red-600 hover:text-red-700 bg-red-50 rounded-lg"
                              >
                                <X className="h-5 w-5" />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center gap-4">
                          <span className="text-gray-600">
                            {currentStore?.regularHours[day].isClosed
                              ? "Closed"
                              : `${currentStore?.regularHours[day].open} - ${currentStore?.regularHours[day].close}`}
                          </span>
                          <button
                            onClick={() => {
                              const dayHours =
                                currentStore?.regularHours?.[day];

                              const formatTime = (time) => {
                                if (!time) return "00:00";
                                const [hours, minutes] = time.split(":");
                                return `${hours.padStart(2, "0")}:${minutes}`;
                              };

                              setEditingTimes({
                                open: formatTime(dayHours?.open),
                                close: formatTime(dayHours?.close),
                              });

                              setEditingDay(day);
                            }}
                            className="p-2 text-blue-600 hover:text-blue-700 bg-blue-50 rounded-lg"
                          >
                            <Edit2 className="h-5 w-5" />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Special Dates Section */}
            {view === "special" && (
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-xl font-bold text-gray-900 mb-6">
                  {selectedStoreName} Special Dates
                </h2>

                {/* Date and Time Picker Section */}
                <div className="bg-gray-50 rounded-xl p-6 mb-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 items-end">
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Date
                      </label>
                      <DatePicker
                        selected={selectedLocalDate}
                        onChange={(date) => {
                          if (date) {
                            setSelectedLocalDate(date);
                            const utcDate = new Date(
                              Date.UTC(
                                date.getFullYear(),
                                date.getMonth(),
                                date.getDate(),
                                0,
                                0,
                                0
                              )
                            );
                            setSelectedDate(utcDate);
                          }
                        }}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select a date"
                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    {!isSpecialDateClosed && (
                      <>
                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Opening Time
                          </label>
                          <select
                            className="w-full p-2 border rounded-lg bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={specialTimes.open}
                            onChange={(e) =>
                              setSpecialTimes((prev) => ({
                                ...prev,
                                open: e.target.value,
                              }))
                            }
                            disabled={isSpecialDateClosed}
                          >
                            {timeOptions.map((time) => (
                              <option key={`open-${time}`} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Closing Time
                          </label>
                          <select
                            className="w-full p-2 border rounded-lg bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            value={specialTimes.close}
                            onChange={(e) =>
                              setSpecialTimes((prev) => ({
                                ...prev,
                                close: e.target.value,
                              }))
                            }
                            disabled={isSpecialDateClosed}
                          >
                            {timeOptions.map((time) => (
                              <option key={`close-${time}`} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}

                    <div className="space-y-4">
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          id="isClosed"
                          checked={isSpecialDateClosed}
                          onChange={(e) =>
                            setIsSpecialDateClosed(e.target.checked)
                          }
                          className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                        />
                        <label
                          htmlFor="isClosed"
                          className="text-sm font-medium text-gray-700"
                        >
                          Closed on this date
                        </label>
                      </div>
                      <button
                        onClick={handleAddSpecialDate}
                        className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed shadow-lg shadow-blue-200"
                        disabled={!selectedDate}
                      >
                        Add Special Date
                      </button>
                    </div>
                  </div>
                </div>

                {/* Special Dates List */}
                <div className="space-y-3">
                  {currentStore.specialDates.map((special) => {
                    const date = new Date(special.date)
                      .toISOString()
                      .split("T")[0];
                    return (
                      <div
                        key={date}
                        className="flex items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
                      >
                        <span className="font-medium text-gray-700">
                          {date}
                        </span>
                        <div className="flex items-center gap-4">
                          <span className="text-gray-600">
                            {special.isClosed
                              ? "Closed"
                              : `${special.open} - ${special.close}`}
                          </span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveSpecialDate(date);
                            }}
                            className="p-2 text-red-600 hover:text-red-700 bg-red-50 rounded-lg"
                          >
                            <X className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}

        {/* Success Message */}
        {showSuccess && (
          <div className="fixed bottom-4 right-4 bg-green-100 border border-green-400 text-green-700 px-6 py-3 rounded-xl shadow-lg">
            <div className="flex items-center gap-2">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="font-medium">
                Store hours updated successfully!
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center">
      You don't have permission to access this page.
    </div>
  );
};

export default StoreHoursDashboard;
