import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import api from "../utils/config/api";
import { checkBooking } from "../utils/config/api";
import { deleteBooking } from "../utils/config/api";
import NotificationAlert from "./NotificationAlert";

function ReservationCheck() {
  const { eventId } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSureCancel, setIsSureCancel] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const [type, setType] = useState("default");

  const [isShowNotification, setIsShowNotification] = useState(false);

  // ! useEffect에서 직접적으로 async 쓰면 안되고, 쓰려면 아래처럼 자체 함수 만들어서 써야함. useEffect는 promise를 반환하면 안됨, 또한 함수가 아닌 값은 반환 못하고 클린업 함수가 되어야함.

  useEffect(() => {
    console.log("URL params:", { eventId, email });

    const fetchBooking = async () => {
      setIsLoading(true);
      try {
        const bookingResponse = await checkBooking(eventId);

        if (!bookingResponse.isSearched) {
          setError(bookingResponse?.message);
        }

        setBooking(bookingResponse.reservation_data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBooking();
  }, [eventId]);

  const onConfirm = async () => {
    try {
      setIsSubmitting(true);
      const result = await deleteBooking(eventId);

      if (result.isDeleted) {
        setModalMessage(result.message);
        setType("success");
      } else {
        setModalMessage(result.message);
        setType("error");
      }
    } catch (error) {
      console.log(error);
      setModalMessage(error.response.data.message);
      setType("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const onClose = async () => {
    setIsShowNotification(false);
  };

  const handleCancel = (message, isModify = false) => {
    setType(isModify ? "modify" : "cancel");
    setModalMessage(message);
    setIsShowNotification(true);
    // console.log("eventId로 노티피케이션 켰음");
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex gap-1">
          <div className="w-2 h-2 rounded-full bg-red-600 animate-bounce"></div>
          <div className="w-2 h-2 rounded-full bg-red-600 animate-bounce [animation-delay:0.2s]"></div>
          <div className="w-2 h-2 rounded-full bg-red-600 animate-bounce [animation-delay:0.4s]"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <button
            onClick={() => navigate("/home")}
            className="text-black hover:text-slate-600 underline"
          >
            Go to Main Page
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <div className="w-full bg-white py-8 px-4 border-b">
        <div className="max-w-4xl mx-auto text-center space-y-3">
          <div className="flex justify-center gap-1 mb-4">
            <div className="w-1 h-1 rounded-full bg-red-500 animate-pulse"></div>
            <div className="w-1 h-1 rounded-full bg-red-400 animate-pulse delay-100"></div>
            <div className="w-1 h-1 rounded-full bg-red-300 animate-pulse delay-200"></div>
          </div>

          <div className="relative w-full">
            <h1 className="text-xl sm:text-2xl md:text-[2rem] leading-tight font-extrabold text-gray-900 px-2 md:px-4">
              Reservation Details
            </h1>
          </div>
        </div>
      </div>

      <div className="sm:h-auto max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6 mt-8">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-gray-600 hover:text-gray-900 mb-6"
        >
          <ChevronLeft className="w-5 h-5" />
          <span>Back</span>
        </button>

        <div className="space-y-6">
          <div className="border-b pb-4">
            <h2 className="text-2xl font-bold text-red-600 mb-4">
              CERAGEM - {booking.store}
            </h2>
            <p className="text-gray-600">Service: {booking.service} </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-4">
            <div>
              <p className="text-sm font-medium text-gray-500">Date & Time</p>
              <p className="text-lg font-semibold">
                {booking.reservation_date} at {booking.reservation_time}
              </p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Name</p>
              <p className="text-lg font-semibold">{booking.name}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Email</p>
              <p className="text-lg font-semibold">{booking.email}</p>
            </div>
            {booking.comments && (
              <div>
                <p className="text-sm font-medium text-gray-500">Comment</p>
                <p className="text-lg font-semibold">{booking.comments}</p>
              </div>
            )}
          </div>

          <div className="flex flex-col sm:flex-row gap-4">
            <button
              onClick={() => {
                handleCancel(
                  "Clicking Confirm will take you to the reservation page and delete your previous reservation.",
                  true
                );
              }}
              className="flex-1 bg-red-600 text-white py-3 px-4 rounded hover:bg-red-700 transition duration-300 ease-in-out"
            >
              {isSubmitting ? (
                <div className="flex gap-1 justify-center">
                  <div className="w-1.5 h-1.5 rounded-full bg-red-500 animate-bounce"></div>
                  <div className="w-1.5 h-1.5 rounded-full bg-red-600 animate-bounce [animation-delay:0.2s]"></div>
                  <div className="w-1.5 h-1.5 rounded-full bg-red-700 animate-bounce [animation-delay:0.4s]"></div>
                </div>
              ) : (
                "Modify Reservation"
              )}
            </button>

            <button
              onClick={() => {
                handleCancel(
                  "Are you sure you want to cancel this reservation?",
                  false
                );
              }}
              disabled={isSubmitting}
              className="flex-1 bg-white border-2 border-black py-3 px-4 rounded hover:bg-black hover:text-white transition duration-300 ease-in-out disabled:opacity-75 relative"
            >
              {isSubmitting ? (
                <div className="flex gap-1 justify-center">
                  <div className="w-1.5 h-1.5 rounded-full bg-black animate-bounce"></div>
                  <div className="w-1.5 h-1.5 rounded-full bg-black animate-bounce [animation-delay:0.2s]"></div>
                  <div className="w-1.5 h-1.5 rounded-full bg-black animate-bounce [animation-delay:0.4s]"></div>
                </div>
              ) : (
                "Cancel Reservation"
              )}
            </button>
          </div>
        </div>
      </div>
      <NotificationAlert
        show={isShowNotification}
        isSubmitting= {isSubmitting}
        message={modalMessage}
        type={type}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </div>
  );
}

export default ReservationCheck;
