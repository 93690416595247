import api from "../../utils/config/api.js";
import { updateUser } from "../type/types.js";

// Action Types
// export const FETCH_STORE_HOURS_REQUEST = 'FETCH_STORE_HOURS_REQUEST';
// export const FETCH_STORE_HOURS_FAILURE = 'FETCH_STORE_HOURS_FAILURE';

export const FETCH_STORE_HOURS_SUCCESS = "FETCH_STORE_HOURS_SUCCESS";

export const fetchStoreHours = () => async (dispatch) => {
  // todo: 디스패치 할 데이터 정해놔야함. 현재는 user에 대한 스토어밖에 없는데 user가 아니라 store거로 바꿔야해.
  try {
    const response = await api.get("/store-hours");
    // console.log("데이터 받기 응답:" + JSON.stringify(response.data));
    if (response.status === 200) {
      //   console.log("데이터 받기 응답 성공");
      // todo: dispatch 하고 reducer에 상태 업데이트 해야돼, 서버에서 주고, dispatch 하는건 모두 객체 형태로 해야함
      // dispatch({})
      await dispatch({
        type: FETCH_STORE_HOURS_SUCCESS,
        payload: response.data.storeHours,
      });
      //   todo: 현재는 return 하는 거로 받아놨는데 compo에서 그냥 use 뭐시기더라, 상태관리 하는거로 가져와야겠다.
    } else {
      throw new Error("No store hours data received");
    }
  } catch (error) {
    // todo: 이해가 안되는게, error 상태 관리 체크를 여기서 왜 하는거야?
    // dispatch({
    //     type: FETCH_STORE_HOURS_FAILURE,
    //     payload: error.message || "Failed to fetch store hours"
    // });
    // error 선언 해야돼
    // console.log("에러:" + error);
    throw new Error(JSON.stringify(error));
  }
};

// Update regular hours
// hours는 객체 형식으로 {open:, close:}로 들어옴
export const updateRegularHours = (storeId, day, hours) => async (dispatch) => {
  // console.log("시간 바꾸기 업데이트 액션:" + storeId, day, hours);
  try {
    const response = await api.put(`/store-hours/regular-hours/${storeId}`, {
      day,
      hours,
    });
    if (response.data) {
      dispatch({
        type: FETCH_STORE_HOURS_SUCCESS,
        payload: response.data.storeHours,
      });
      //   console.log("정규시간 업데이트 성공:" + JSON.stringify(response.data));
      //   return response.data;
    } else {
      throw new Error("updateRegularHours Server Error");
    }
  } catch (error) {
    // console.log("정규시간 바꾸기:" + error);
    throw new Error(error.response.data.message);
  }
};

// Add/Update special date
export const updateSpecialDate = (storeId, dateData) => async (dispatch) => {
  try {
    // console.log("시간 바꾸기 업데이트 액션:" + storeId, dateData);
    // todo: 백데이터는 정상적으로 날짜 UTC와 동일하게 받으나, 여기선 .UTCSTRING 으로 안 해놔서 출력이 UTC 시간으로 잘 되지 않음.
    const response = await api.put(
      `/store-hours/special-date/${storeId}`,
      dateData
    );
    if (response.data) {
      dispatch({
        type: FETCH_STORE_HOURS_SUCCESS,
        payload: response.data.storeHours,
      });
      //   return response.data.storeHours;
    } else {
      throw new Error("updateSpecialDate Server Error");
    }
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// Delete special date
export const deleteSpecialDate = (storeId, date) => async (dispatch) => {
  try {
    // console.log("삭제 요청 들어옴 액션 storeId, date:" + storeId + "/" + date);

    const response = await api.delete(
      `/store-hours/special-date/${storeId}/${date}`
    );
    // console.log("데이터:" + JSON.stringify(response.data.storeHours));
    if (response.data) {
      dispatch({
        type: FETCH_STORE_HOURS_SUCCESS,
        payload: response.data.storeHours,
      });
      //   return response.data;
    } else {
      throw new Error("deleteSpecialDate Server Error");
    }
  } catch (error) {
    // console.log("특별 날짜 삭제 에러:" + error);
    throw new Error(error.response.data.message);
  }
};
