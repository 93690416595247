import React, { useState, useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import Header from "./components/Header";
import Login from "./components/Login";
import Home from "./components/Home.jsx";
import { checkAuth } from "./actions/action/authActions.js";
import { getAccessToken } from "./auth/authManager.js";
import { LoadingSpinner } from "./components/Login";
import Subscription from "./components/Subscription.jsx";
import FloatingContactButton from "./components/FloatingContactButton.jsx";
import ReservationOth from "./components/ReservationOth.jsx";
import FullertonReservation from "./components/FullertonReservation.jsx";
import SwipeNavigation from "./components/SwipeNavigation.jsx";
import ReservationCheck from "./components/ReservationCheckPage.jsx";
import StoreHoursDashboard from "./components/StoreHoursDashBoard.jsx";
// const Home = lazy(() => import("./components/Home.jsx"));
const AuthNotification = lazy(() =>
  import("./components/AuthNotification.jsx")
);
const Benefits = lazy(() => import("./components/Benefits.jsx"));
const Events = lazy(() => import("./components/Events.jsx"));
const Profile = lazy(() => import("./components/Profile.jsx"));
const BottomNav = lazy(() => import("./components/BottomNav"));
const PopupPWAInstallPrompt = lazy(() => import("./components/PWAPopup.jsx"));
const FloatingQRButton = lazy(() =>
  import("./components/FloatingQrButton.jsx")
);
const MessengerShare = lazy(() => import("./components/MessengerShare.jsx"));
const EmailVerification = lazy(() =>
  import("./components/EmailVerification.jsx")
);
const Reservation = lazy(() => import("./components/Reservation.jsx"));
const Register = lazy(() => import("./components/Register.jsx"));
const Activities = lazy(() => import("./components/Activites.jsx"));
const Allforums = lazy(() => import("./components/AllForums.jsx"));
const GuidePage = lazy(() => import("./components/Guide.jsx"));
const RewardsPage = lazy(() => import("./components/Reward.jsx"));
const Stores = lazy(() => import("./components/Store.jsx"));
const IdentifyModal = lazy(() => import("./components/IdentifyModal.jsx"));
const TermsOfService = lazy(() => import("./components/TermsOfService.jsx"));
const LoginRecovery = lazy(() => import("./components/LoginRecovery.jsx"));
const ResetPassword = lazy(() => import("./components/ResetPassword.jsx"));
const SubscriptionAlert = lazy(() =>
  import("./components/SubscriptionAlert.jsx")
);
const AllReservation = lazy(() => import("./components/AllRerservation.jsx"));

const PayPage = lazy(() => import("./components/PayPage.jsx"));

const pageTransition = {
  initial: { opacity: 0.8 },
  animate: { opacity: 1 },
};

const transitionDuration = 0.3;

function ProtectedComponent({ component: Component, ...rest }) {
  const { isAuthenticated } = useSelector((state) => state.auth);
  // const token = getAccessToken();
  const location = useLocation();
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(!isAuthenticated);

  // if (loading) {
  //   return <LoadingSpinner />;
  // }

  useEffect(() => {
    setShowNotification(!isAuthenticated);
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return (
      <>
        <AuthNotification
          isVisible={showNotification}
          onClose={() => setShowNotification(false)}
          onLogin={() => navigate("/login", { state: { from: location } })}
        />
        {/* <Navigate to="/login" state={{ from: location }} replace /> */}
      </>
    );
  }
  return (
    <motion.div
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
      {...pageTransition}
      transition={{ duration: transitionDuration }}
    >
      <Component {...rest} />
    </motion.div>
  );
}

function AnimatedRoutes() {
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const pageTransition = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <AnimatePresence mode="wait">
      {/* 1. AnimatePresence란
    Framer Motion 라이브러리에서 제공하는 컴포넌트, wait 모드 사용하면 자식 요소가 DOM 에서 추가되거나 제거될 때 애니메이션을 적용할 수 있게 해준다. 
    */}
      {/* 2. mode = "wait"란
      애니메이션이 발생할 떄의 동작 방식, 애니메이션이 끝날 떄 까지 다음 애니메이션 기다리도록 하는거. 즉 현재 애니메이션이 작동중이면 다음 애니메이션 작동 x
      */}
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
            >
              <Navigate to="/home" replace></Navigate>
            </motion.div>
          }
          // element={
          //   token && isAuthenticated ? (
          //     // ? replace 쓰면 현재의 URL을 새로운 URL로 교체, 즉 사용자가 뒤로가기 버튼 눌렀을 때 이전 URL로 돌아가지 않음. replace가 없으면 새로운 URL이 히스토리 스택에 추가됨
          //     <Navigate to="/home" replace />
          //   ) : (
          //     <Navigate to="/login" replace />
          //   )
          // }
        />

        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route
          path="/admin/reservation/dashboard"
          element={<ProtectedComponent component={StoreHoursDashboard} />}
        />
        {/* <Route
          path="/subscription"
          element={
            <ProtectedComponent component={Subscription}></ProtectedComponent>
          }
        /> */}
        <Route
          path="/payPage"
          element={<ProtectedComponent component={PayPage} />}
        />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route
          path="/verify-email/:token"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
              onAnimationComplete={() => window.scrollTo(0, 0)}
            >
              <EmailVerification />
            </motion.div>
          }
        />
        <Route
          path="/booking/:eventId"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
              onAnimationComplete={() => window.scrollTo(0, 0)}
            >
              <ReservationCheck />
            </motion.div>
          }
        />
        <Route
          path="/login/recovery"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
            >
              <LoginRecovery />
            </motion.div>
          }
        ></Route>
        <Route
          path="/home"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
            >
              <Home></Home>
            </motion.div>
          }
        />
        <Route
          path="/activities"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
            >
              <Activities />
            </motion.div>
          }
        />
        {/* <Route path="/subscription" element={<Subscription></Subscription>} /> */}

        <Route
          path="/subscription"
          element={
            <ProtectedComponent component={Subscription}></ProtectedComponent>
          }
        ></Route>

        {/* <Route path="/subscription/alert" element={<ProtectedComponent component={SubscriptionAlert}></ProtectedComponent>}></Route>
         */}
        <Route
          path="/stores"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
            >
              <Stores />
            </motion.div>
          }
        />
        <Route
          path="/benefits"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
            >
              <Benefits></Benefits>
            </motion.div>
          }
        />
        {/* <Route
          path="/reservation"
          element={<ProtectedComponent component={Reservation} />}
        /> */}

        <Route
          path="/reservation"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
              onAnimationComplete={() => window.scrollTo(0, 0)}
            >
              <Reservation />
            </motion.div>
          }
        />
        <Route
          path="/reservation/oth"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
              onAnimationComplete={() => window.scrollTo(0, 0)}
            >
              <ReservationOth />
            </motion.div>
          }
        />
        <Route
          path="/reservation/all"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
              onAnimationComplete={() => window.scrollTo(0, 0)}
            >
              <AllReservation />
            </motion.div>
          }
        />
        <Route
          path="/reservation/fullerton"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
              onAnimationComplete={() => window.scrollTo(0, 0)}
            >
              <FullertonReservation />
            </motion.div>
          }
        />
        <Route
          path="/reservation/fullerton"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
              onAnimationComplete={() => window.scrollTo(0, 0)}
            >
              <FullertonReservation />
            </motion.div>
          }
        />
        <Route
          path="/events"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
            >
              <Events></Events>
            </motion.div>
          }
        />
        <Route
          path="/guide"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
            >
              <GuidePage></GuidePage>
            </motion.div>
          }
        />
        <Route
          path="/reward"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
            >
              <RewardsPage />
            </motion.div>
          }
        />
        <Route
          path="/allforums"
          element={
            <motion.div
              {...pageTransition}
              transition={{ duration: transitionDuration }}
            >
              <Allforums />
            </motion.div>
          }
        />
        <Route
          path="/profile"
          element={<ProtectedComponent component={Profile} />}
        />
        <Route
          path="/login"
          element={
            <motion.div {...pageTransition}>
              <Login />
            </motion.div>
          }
        />
        <Route
          path="/register"
          element={
            <motion.div {...pageTransition}>
              <Register />
            </motion.div>
          }
        />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  // const token = getAccessToken();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);
  // ! dispatch 실행될떄바다 checkAuth 해야되는데, 이래서 모든 서버 응답을 dispatch로 실행하는게 맞는거같음.

  return (
    <Router>
      <SwipeNavigation>
        <div className="flex flex-col min-h-screen bg-gray-100">
          <Header className=" top-0 left-0 right-0 z-10" />
          <Suspense fallback={<LoadingSpinner />}>
            {/* 리액트에서 비동기적으로 데이터 로드할 떄 사용되는 Suspense. 로딩 상태에 사용함, fallback 은 로딩 상태일떄 뭘 보여주냐라는 속성. Route로 치면 element 같은거.  */}
            {/* 이 Suspense는 비동기 작업을 위한 거라, 비동기 작업 끝나면 자동으로 자식 컴포넌트가 랜더링 된다.  */}
            <main className="flex-grow mb-16 overflow-y-auto">
              <div className="w-full px-0 sm:px-4 mx-auto sm:max-w-4xl -2">
                <AnimatedRoutes />
              </div>
              <FloatingContactButton />
              <SubscriptionAlert />
              <FloatingQRButton className="" />
              <MessengerShare />
            </main>

            <BottomNav className="fixed bottom-0 left-0 right-0 z-10" />

            <PopupPWAInstallPrompt />
            <IdentifyModal />
            {/* 이거 쿠폰 인식 모달임 */}
          </Suspense>
        </div>
      </SwipeNavigation>
    </Router>
  );
}

export default App;
