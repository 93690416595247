import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

const NotificationAlert = ({
  show,
  message,
  type,
  onConfirm,
  onClose,
  isSubmitting,
}) => {
  const navigate = useNavigate();

  const handleConfirm = async () => {
    await onConfirm();
    if (type === "modify") {
      navigate("/reservation/all");
    }
  };

  const getIcon = () => {
    switch (type) {
      case "success":
        return (
          <svg
            className="w-8 h-8 text-green-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        );
      case "error":
        return (
          <svg
            className="w-8 h-8 text-red-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          {/* 배경 오버레이 */}
          <div className="fixed inset-0 bg-black bg-opacity-25" />

          {/* 알림창 */}
          <div className="relative max-w-sm w-full mx-4 bg-white shadow-xl rounded-xl overflow-hidden">
            {/* 메시지 섹션 */}
            <div className="p-6">
              <div className="flex flex-col items-center text-center">
                {(type === "success" || type === "error") && (
                  <div className="mb-4">{getIcon()}</div>
                )}
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {message}
                </h3>
              </div>
            </div>

            {/* 버튼 섹션 */}
            <div className="border-t border-gray-200">
              <div className="flex divide-x divide-gray-200">
                {(type === "cancel" ||
                  type === "modify" ||
                  type === "delete") && (
                  <button
                    onClick={handleConfirm}
                    className="flex-1 px-4 py-3 text-sm font-medium text-red-600 hover:bg-red-50 focus:outline-none transition-colors duration-200"
                  >
                    {isSubmitting ? (
                      <div className="flex gap-1 justify-center">
                        <div className="w-1.5 h-1.5 rounded-full bg-red-500 animate-bounce"></div>
                        <div className="w-1.5 h-1.5 rounded-full bg-red-600 animate-bounce [animation-delay:0.2s]"></div>
                        <div className="w-1.5 h-1.5 rounded-full bg-red-700 animate-bounce [animation-delay:0.4s]"></div>
                      </div>
                    ) : (
                      "Confirm"
                    )}
                  </button>
                )}
                <button
                  onClick={onClose}
                  className="flex-1 px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none transition-colors duration-200"
                >
                  {isSubmitting ? (
                    <div className="flex gap-1 justify-center">
                      <div className="w-1.5 h-1.5 rounded-full bg-black animate-bounce"></div>
                      <div className="w-1.5 h-1.5 rounded-full bg-black animate-bounce [animation-delay:0.2s]"></div>
                      <div className="w-1.5 h-1.5 rounded-full bg-black animate-bounce [animation-delay:0.4s]"></div>
                    </div>
                  ) : (
                    "Dismiss"
                  )}
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NotificationAlert;
