import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { beforePaymentLogic, subscribe } from "../actions/action/subscriptionActions.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { emailRegex, phoneNumberRegex } from "../hooks/formatRegax.js";

export const openTerms = () => {
  window.open("/terms-of-service", "Terms of Service", "width=800,height=600");
};

// todo: 이거 로그인 안 되면 못 들어오게 해야함.
// todo: 그리고 그냥 name이랑 phoneNumber 양식 같은거 square 에 맞게 하되, 우리 어플에서는 양식 다르게 해야할듯.
function Subscription() {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    birthday: "",
    phoneNumber: "",
    termsAccepted: false,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else if (user) {
      const nameParts = user.name ? user.name.split(" ") : ["", ""];
      const firstName = nameParts[0];
      const lastName =
        nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";

      setFormData({
        email: user.email || "",
        firstName: firstName,
        lastName: lastName !== firstName ? lastName : "",
        birthday: "",
        phoneNumber: user.phoneNumber || "",
        termsAccepted: false,
      });
    }
  }, [isAuthenticated, user, navigate]);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  //   useEffect(() => {
  //     if (user) {
  //       setFormData(prevData => ({
  //         ...prevData,
  //         email: user.email || "",
  //         phoneNumber: user.phoneNumber || ""
  //       }));
  //     }
  //   }, [user]);

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, "");
    if (phoneNumber.length <= 3) return phoneNumber;
    if (phoneNumber.length <= 6)
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleChange = (e) => {
    setSuccess("");
    setError("");

    if (e.target.name === "phoneNumber") {
      const formattedNumber = formatPhoneNumber(e.target.value);
      setFormData({ ...formData, [e.target.name]: formattedNumber });
    } else if (e.target.type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.checked,
      }));
    } else if (e.target.name === "birthday") {
      // Ensure the birthday is in "YYYY-MM-DD" format
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (dateRegex.test(e.target.value)) {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      } else {
        // todo: 데이터 형식 일치하지 않으면 어떻게 할건데?
        setError("Invalid date format");
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess("");
    setError("");

    if (!formData.termsAccepted) {
      return setError("You must accept the Terms of Service to subscribe.");
    }
    if (!emailRegex.test(formData.email)) {
      return setError("Please enter a valid email address.");
    }
    if (!phoneNumberRegex.test(formData.phoneNumber)) {
      return setError("The phone number format must be '111-111-1111'.");
    }

    try {
      //! square는 +1 123 1234 1234 이런 형식으로 받음. 띄어쓰기 뺴고.

      // const result = await dispatch(subscribe(formData));
      const result = await dispatch(beforePaymentLogic(formData));
      // todo: 이거 subscribe가 아니라 BeforePaymentLogic으로 해야할듯

      // console.log("프론트 결과1:" + JSON.stringify(result));
      

      if (result.status === 200) {
        // console.log("프론트 결과2:" + result);
        
        setSuccess(result.message);
        
        navigate('/payPage');
        
        // window.location.href = "https://square.link/u/S0Cr86Bh?src=embed";
      } else {
        // console.log('프런트 결과3' + result);
        setError(result.message);
      }
    } catch (error) {
      console.log('에러결과:'+error);
      console.log('에러결과:'+ JSON.stringify(error));
      // console.error(err);
      setError(error.response.message ?? "An unexpected error occurred. Please try again later.");
    }

    
    // console.log('생일 형식 확인:'+formData.birthday);

   
  };

  if (!isAuthenticated || !user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-400 via-pink-500 to-red-500 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
              <h2 className="text-center text-3xl font-extrabold text-gray-900">
                Subscribe to Premium
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Unlock exclusive content and features
              </p>
            </div>
            
            <form className="mt-8 space-y-6" onSubmit={handleSubmit} >
              <div className="rounded-md shadow-sm -space-y-px">
                <div className="grid grid-cols-1 gap-y-2">
                  <div>
                    <label htmlFor="firstName" className="sr-only">
                      Given Name
                    </label>
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Given Name"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="sr-only">
                      Family Name
                    </label>
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Family Name"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Email address"
                      value={formData.email}
                      readOnly
                      //   onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="birthday" className="sr-only">
                      Birthday
                    </label>
                    {/* <p className="text-xs text-gray-500">
                      Please enter your date of birth
                    </p> */}
                    {/* DatePicker로 바꾸는고거 고려해야 할듯, subscription 에 똑같은거 있음.  */}
                    <div className="relative">
                      <DatePicker
                        id="birthday"
                        selected={
                          formData.birthday ? new Date(formData.birthday) : null
                        }
                        onChange={(date) =>
                          setFormData({
                            ...formData,
                            birthday: date
                              ? date.toISOString().split("T")[0]
                              : "",
                          })
                        }
                        dateFormat="MMMM d, yyyy"
                        className="appearance-none rounded-none block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholderText="Select your birthday"
                        wrapperClassName="w-full"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        maxDate={new Date()}
                        required
                      />
                      <FaCalendarAlt className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="phoneNumber" className="sr-only">
                      Phone Number
                    </label>
                    <input
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      autoComplete="tel"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Phone Number (123-456-7890)"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      maxLength={12}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="termsAccepted"
                    name="termsAccepted"
                    type="checkbox"
                    checked={formData.termsAccepted}
                    onChange={handleChange}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="termsAccepted"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    I have read and accept the{" "}
                    <button
                      type="button"
                      onClick={openTerms}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Terms of Service
                    </button>
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  // onClick={()=> navigate("/payPage")}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                >
                  Submit 
                </button>
                <p className="mt-2 text-xs text-gray-600">
                  * Payments are non-refundable. If you cancel this month, you
                  won’t be charged for the next month.
                </p>
              </div>
            </form>
            {success && (
              <div className="mt-3 text-center text-sm text-green-600 bg-green-100 p-2 rounded">
                {success}
              </div>
            )}
            {error && (
              <div className="mt-3 text-center text-sm text-red-600 bg-red-100 p-2 rounded">
                {error}
              </div>
            )}
          </div>
          <div className="mt-6">
            <h3 className="text-lg font-medium text-gray-900">
              Premium Benefits:
            </h3>
            <ul className="mt-2 list-disc list-inside text-sm text-gray-600">
              <li>Unlimited access to all premium content</li>
              <li>Various bonus points provided and coupon usage</li>
              <li>Priority customer support</li>
              <li>Early access to new features and content</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
