import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ReservationTimes = ({ store, date, selectedService, onTimeSelect }) => {
  const [availableTimes, setAvailableTimes] = useState([]);
  const storeHours = useSelector((state) => state.store?.hours ?? []);

  const convertTo12Hour = (time24) => {
    if (time24 === "Closed" || time24.includes("Appointment Only")) {
      return time24;
    }

    const [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? " PM" : " AM";
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes.toString().padStart(2, "0")}${period}`;
  };

  useEffect(() => {
    // console.log("스토어 시간:" + JSON.stringify(storeHours[0]));

    if (store && storeHours.length > 0) {
      const selectedStoreHours = storeHours.find(
        (s) => s.storeId.toString() === store
      );
      //   console.log("선택된 시간:" + JSON.stringify(selectedStoreHours));

      //   todo: specialDate 정보로 dateBlocked 하도록 해야될거 같은데 여기서 하는건 아닌거 같음

      if (selectedStoreHours && date) {
        const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
        const hours = selectedStoreHours.regularHours[dayOfWeek];

        // Check for special dates
        const dateString = date.toISOString().split("T")[0];
        // console.log("데이트 스트링:" + dateString);
        // console.log(
        //   "선택 시간 및 특별 날짜:" +
        //     JSON.stringify(selectedStoreHours.specialDates)
        // );
        const specialDate = selectedStoreHours.specialDates.find(
          (special) => special.date.split("T")[0] === dateString
        );
        // console.log("스페셜 날짜:" + JSON.stringify(specialDate));
        if (specialDate) {
          if (specialDate.isClosed) {
            // console.log("특정 날짜 닫쳤대요:" + specialDate.date);
            setAvailableTimes(["Closed"]);
            return;
          } else {
            // Use special date hours regardless of regular hours
            const times = generateTimeSlots(
              specialDate.open,
              specialDate.close
            );
            setAvailableTimes(times);
          }
          // // Use special date hours
          // const times = generateTimeSlots(specialDate.open, specialDate.close);
          // setAvailableTimes(times);
        } else if (hours && !hours.isClosed) {
          // Use regular hours
          const times = generateTimeSlots(hours.open, hours.close);
          setAvailableTimes(times);
        } else {
          setAvailableTimes(["Closed"]);
        }
      }
    }
  }, [store, date, selectedService, storeHours]);

  const generateTimeSlots = (start, end) => {
    const times = [];
    const [startHour, startMinute] = start.split(":").map(Number);
    const [endHour, endMinute] = end.split(":").map(Number);

    let currentHour = startHour;
    let currentMinute = startMinute;

    while (
      currentHour < endHour ||
      (currentHour === endHour && currentMinute <= endMinute)
    ) {
      const time = `${currentHour.toString().padStart(2, "0")}:${currentMinute
        .toString()
        .padStart(2, "0")}`;
      times.push(time);

      currentMinute += 30;
      if (currentMinute >= 60) {
        currentHour += 1;
        currentMinute = 0;
      }
    }

    return times;
  };

  return (
    <select
      onChange={(e) => onTimeSelect(e.target.value)}
      className="mt-1 block w-full p-3 rounded-md border-gray-200 border-2 shadow-sm focus:border-gray-400 focus:ring focus:ring-gray-400 focus:ring-opacity-50 outline-none appearance-none"
      disabled={availableTimes.length === 0}
    >
      <option value="">Choose a time</option>
      {availableTimes.map((time) => (
        <option key={time} value={time}>
          {convertTo12Hour(time)}
        </option>
      ))}
    </select>
  );
};

export default ReservationTimes;
