// src/reducers/index.js
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import storeHourReducer from './storeHourReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  store: storeHourReducer
});

export default rootReducer;
