import axios from "axios";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "" // Empty string for relative URLs in production
    : "http://localhost:5005";

const api = axios.create({
  baseURL: "/api",
  withCredentials: true,
  // cors 설정으로 다른 출처 접근 허용했음, 다른 도메인에 api 요청 보낼 떄 이거 true하면 쿠키가 포함된 요청을 보낼 수 있는거임.
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// api.interceptors.request.use(
//   // success
//   async (config) => {
//     const token = getAccessToken();
//     if (token) {
//       config.headers["x-auth-token"] = token;
//     }

//     // else {
//     //   delete config.headers["x-auth-token"];
//     //   // Bearer이 oauth, 우리는 x-auth-token
//     // }

//     return config;
//   },

//   (error) => Promise.reject(error)
// );

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            // originalRequest.headers["x-auth-token"] = token;
            return api(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const res = await api.post("/auth/token/refresh");
        // const { token } = res.data;
        // setAccessToken(token);
        // api.defaults.headers.common["x-auth-token"] = token;
        processQueue(null);
        return api(originalRequest);
      } catch (refreshError) {
        console.log("리프레시 에러:" + refreshError);
        processQueue(refreshError, null);
        // clearAccessToken();
        // Redirect to login or dispatch logout action
        window.location = "/login"; // or use your routing method
        
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export const getProfile = () => {
  return api.get("/profile");
};

export const checkBooking = async (bookingId) => {
  try {
    console.log("bookingId:" + bookingId);
    const response = await api.get(`/booking/${bookingId}`);
    if (response.status === 200) {
      return {
        reservation_data: response.data,
        message: response?.data?.message,
        isSearched: true,
      };
    } else {
      return {
        reservation_data: response.data,
        message: response.data.message,
        isSearched: false,
      };
    }
  } catch (error) {
    console.log("checkbooking api 프론트엔드 util 에러" + error);
    return { message: error.response.data.message, isSearched: false };
  }
};

export const deleteBooking = async (bookingId) => {
  try {
    const response = await api.delete(
      `/booking/cancel-reservation/${bookingId}`
    );

    if (response.status === 200) {
      // todo: modal 보여줘서 삭제 완료됐다고 보여주기 해야되는데 일단
      return { isDeleted: true, message: response.data.message };
    } else {
      // todo: modal 에서 삭제 오류 생겼다고 알려주기
      return { isDeleted: false, message: response.data.message };
    }
  } catch (error) {
    // todo: modal 보여줘서 삭제 오류생겼따고 알려주기
    return { isDeleted: false, message: error.response.data.message };
  }
};

export default api;
